import React, { useState } from 'react'
import './searchInput.scss';
// Materia
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const SearchInput = ({ 
  cdrsCompanies,
  queryCompanyName,
  handleOnChangeCompanyName,
  queryCompanyReference,
  handleOnChangeCompanyReference,
  resetInput,
}) => {
  const [searchType, setSearchType] = useState('companyReference');
  
  // permet de faire une recherche par nom de société
  const companyNameInput = () => {
    const companiesName = Object.keys(cdrsCompanies).map((companyName) => ({ name: companyName }))

    // permet de trier les sociétés par lettre
    const options = companiesName.map((companyName) => {
      const firstLetter = companyName.name[0].toUpperCase();

      return {
        firstLetter: /[A-Za-zÀ-ÖØ-öø-ÿ]/.test(firstLetter) ? firstLetter : '0-9',
        ...companyName,
      };
    });

    return (
      <Autocomplete
        id="search-input"
        className="search-input_autocomplete search-input_autocomplete--name"
        freeSolo
        options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
        isOptionEqualToValue={(option, value) => option.name  === value?.name || ""}
        groupBy={(option) => option.firstLetter}
        getOptionLabel={(option) => option.name || option}
        sx={{ width: 450 }}
        renderInput={(params) => <TextField {...params} label="Rechercher une société par nom" />}
        inputValue={queryCompanyName}
        onInputChange={handleOnChangeCompanyName}
      />
    )
  }

  // permet de faire une recherche par numéro de reférence de société
  const companyReferenceInput = () => {
    const companiesReference = Object.entries(cdrsCompanies)
      .map(([companyName, { companyReference }]) => ({ reference: companyReference, id: companyName }))
      .filter(({ reference }) => '' !== reference)
    ;

    return (
      <Autocomplete
        id="search-input"
        className="search-input_autocomplete search-input_autocomplete--reference"
        freeSolo
        options={companiesReference}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.reference}
            </li>
          );
        }}
        isOptionEqualToValue={(option, value) => option.reference  === value?.reference || ""}
        getOptionLabel={(option) => option.reference || option}
        sx={{ width: 450 }}
        renderInput={(params) => <TextField {...params} label="Rechercher une société par reference" />}
        inputValue={queryCompanyReference}
        onInputChange={handleOnChangeCompanyReference}
      />
    )
  }

  const handleChangeInputType = (e, newSearchType) => {
    resetInput();
    setSearchType(newSearchType)
  }

  return (
    <div className='search-input'>
      {
        'companyReference' === searchType ?  companyReferenceInput() : companyNameInput()
      }
      <ToggleButtonGroup
          color="primary"
          value={searchType}
          exclusive
          onChange={handleChangeInputType}
          aria-label="Platform"
          className="search-input_toggle--group"
        >
          <ToggleButton className="search-input_toggle--element  search-input_toggle--reference" value="companyReference">Référence</ToggleButton>
          <ToggleButton className="search-input_toggle--element search-input_toggle--name" value="companyName">Nom</ToggleButton>
        </ToggleButtonGroup>
      </div>
  );
}

export default React.memo(SearchInput)