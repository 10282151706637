import mem from "mem";

import { axiosPublic } from "./axiosPublic";

const refreshTokenFn = async () => {
  try {
    const session = JSON.parse(localStorage.getItem("session"));

    const response = await axiosPublic.post("/token/refresh", {
      refreshToken: session?.refresh_token,
    });

    const { token } = response.data;

    if (!token) {
      localStorage.removeItem("session");
      localStorage.removeItem("user");
    }

    localStorage.setItem("session", JSON.stringify(response.data));

    return session;

  } catch (error) {
    localStorage.removeItem("session");
    localStorage.removeItem("user");
  }
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});