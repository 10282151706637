import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";

const auth = getAuth();

const Logout = () => {
  localStorage.removeItem("session");
  localStorage.removeItem("user");

  const navigate = useNavigate()

  signOut(auth).then(() => {
    // Sign-out successful.
  }).catch((error) => {
    // An error happened.
  });

  useEffect(() => {
    signOut(auth)
    navigate('/login');
  }, [navigate])
  
  return (
    <></>
  )
}

export default Logout