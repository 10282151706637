import React, { useContext, useMemo } from 'react';
import { StoreContext  } from '../../utils/contexts/store';

// Materia
import { Box, Checkbox, Typography, FormControlLabel } from '@mui/material';

const CdrFilter = ({ companyCardName }) => {
  const {
    cdrs: [cdrs],
    callNumbersChecked: [callNumbersChecked, setCallNumbersChecked],
    areAllChecked: [areAllChecked, setAreAllChecked],
  } = useContext(StoreContext)

  // permet de traiter le nom de la société provenant de CDR comme un tableau
  const companyNameList = useMemo(() => (
    typeof companyCardName !== 'object' ? [companyCardName] : companyCardName 
  ), [companyCardName])

  // liste des numéros par société
  const callNumbersByCompany = useMemo(() => (
    cdrs
      .filter((cdr) => companyNameList.includes(cdr.companyName))
      .reduce((obj, acc) => ({
          ...obj,
          [acc.companyName]: [ ...obj?.[acc.companyName] ?? '', acc.callNumber ]
            // supprime les doublons
            .filter((value, index, array) => array.indexOf(value) === index)
      }), {})
  ), [cdrs, companyNameList])
  
  // selectionne ou deselectionne tous les numéros
  const toggleAllChecked = () => {
    const newCallNumbersCheckedCompany = Object.keys(callNumbersByCompany)
      .map((companyName) => ({
        [companyName]: ({
          ...callNumbersByCompany[companyName]
            .map(
              (callNumber) => undefined !== areAllChecked?.[companyName]
                ? { [callNumber]: !areAllChecked[companyName] }
                : { [callNumber]: false }
            )
            .reduce(
              (obj, item) => Object.assign(obj, { ...item }),
              {}
            )
        })
      }))
      // recrée un objet suite au map
      .reduce(
        (obj, item) => Object.assign(obj, { ...item }),
        {},
      )

    const newAreAllChecked = Object.keys(callNumbersByCompany)
      .map((companyName) => ({
        [companyName] : (
          undefined !== areAllChecked?.[companyName] 
            ? !areAllChecked[companyName]
            // si c'est le premier click sur le bouton "tous selectionner" on veut que la case soit décoché
            : false
        )
      }))
      .reduce(
        (obj, item) => Object.assign(obj, { ...item }),
        {}
      )
    
    // définit la valeur de "Selectionner tout"
    setAreAllChecked({
      ...areAllChecked,
      ...newAreAllChecked,
    })

    // définit la valeur de pour chaque numérp
    setCallNumbersChecked({
      ...callNumbersChecked,
      ...newCallNumbersCheckedCompany,
    })
  }

  /**
   * Apres que l'utilisateur est coché/décoché un numéros
   * verifie si toutes les numéros sont coché/décochés
   * et coche/decoche "tous selectionné"
   */
  const defineAllCallNumberChecked = (companyName, newCallNumbersChecked) => {
    const defaultCallNumbersByCompany = callNumbersByCompany[companyName]
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: true,
        });
      }, {})
    ;

    const currentCallNumbersByCompany = { ...defaultCallNumbersByCompany, ...newCallNumbersChecked[companyName] }

    const isUniqueArray = [...new Set(Object.values(currentCallNumbersByCompany))]

    if (1 === isUniqueArray.length) {
      setAreAllChecked({
        ...areAllChecked,
        [companyName] : isUniqueArray[0],
      })
    }
    else {
      setAreAllChecked({ 
        ...areAllChecked,
        [companyName] : false,
      })
    }
  }

  /**
   * Coche/decoche un numéro
   * 
   * @param {*} callNumber Le numéro qui vient d'être coché/décoché
   */
  const handleChangeCallNumber = (companyName, callNumber) => {
    const newCallNumbersChecked= {
      ...callNumbersChecked,
      [companyName]: {
        ...callNumbersChecked?.[companyName] ?? {},
        [callNumber]: (
          undefined !== callNumbersChecked?.[companyName]?.[callNumber]
            ? !callNumbersChecked?.[companyName]?.[callNumber]
            // si c'est le premier click sur un numéro, on veut qu'il soit déselectionné
            : false 
          ),
        }
      }
    
    setCallNumbersChecked(newCallNumbersChecked)
    
    defineAllCallNumberChecked(companyName, newCallNumbersChecked)
  };

  // Listes des numéros qui pourront être filtré a cdrsCompanies
  const callNumbersInput = (
    <Box sx={{ display: 'flex', flexDirection: 'column', margin: 'auto', width: 'fit-content' }}>
      { callNumbersByCompany && (
        Object.keys(callNumbersByCompany).map(
          (companyName) => callNumbersByCompany[companyName].map(
            (callNumber) => (
              <FormControlLabel
                label={<Typography fontSize="1.2rem" variant="body1">{callNumber}</Typography>}
                control={
                  <Checkbox 
                    size="large"
                    checked={
                      undefined !== callNumbersChecked?.[companyName]?.[callNumber]
                        ? callNumbersChecked[companyName][callNumber]
                        : true
                    } 
                    onChange={() => handleChangeCallNumber(companyName, callNumber)}     
                  />
                }
                key={callNumber}
              />
            )
          )
        )
      )}
    </Box>
  );
  
  return (
    <div className={`cdr-filter`}>
      <FormControlLabel
        className='cdr-filter_form'
        label={<Typography fontSize="1.2rem" variant="body1">Selectionner tout</Typography>}
        control={
          <Checkbox
            size="large" 
            checked={ companyNameList
              .map((companyName) => areAllChecked?.[companyName] ?? true)
              .reduce((obj, acc) => obj && acc, true)
            }
            onChange={toggleAllChecked}
          />
        }
      />
      {callNumbersInput}
    </div>
  )
}

export default React.memo(CdrFilter);