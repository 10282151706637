import './import.scss';
// Components
import ImportIcon from '../ImportIcon';
// Materia
import { Typography } from '@mui/material';

const Import = () => {

  /**
   * Retourne le mois et l'année précendent en français de la Date en parametre
   * 
   * @param {Date} date
   * @returns 
   */
  const getDate = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    const newDate = date.toLocaleDateString("fr-FR", {month: 'long', year: 'numeric'});

    return newDate.charAt(0).toUpperCase() + newDate.slice(1);
  }

  return (
    <div className="import">
      <Typography variant="h1" className="import_date">
        { getDate() }
      </Typography>
      <div className="import_container">
        <Typography component="span" variant="h4" className="import_no-data-msg">
          Déposer vos CDR au format ".zip" ci-dessous :
        </Typography>
        <ImportIcon/>
      </div>
    </div>
  )
}

export default Import