import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

// Components
import Loader from '../Loader';
// Materia
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getDb } from '../../firebase/fibaseConfig';

const theme = createTheme();
const db = getDb();
const auth = getAuth();

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState(" ");

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  const handleOnClickSignIn= (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    signInWithEmailAndPassword(auth, data.get('email'), data.get('password'))
    .then((userCredential) => {
      setIsLoading(true)
      setErrorMsg("");
      const user = userCredential.user;

      localStorage.setItem('user', user);
      navigate('/');
      setIsLoading(false)
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      setErrorMsg(errorMessage);
      setIsLoading(false)
    })
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Se connecter
          </Typography>
          <Box component="form" onSubmit={handleOnClickSignIn} noValidate sx={{ mt: 1 }}>
            { errorMsg && (
              <Typography component="span" sx={{"textAlign": "center"}} color="error">
                { errorMsg }
              </Typography>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              sx={{ mb: 3 }}
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            { isLoading 
              ? (
                <Loader size="50px" />
              )
              : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                >
                  Se connecter
                </Button>
              )
            }
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default Login;