import React, { useContext, useCallback, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { axiosPrivate as axios } from '../../utils/axios/axiosPrivate';
import { StoreContext  } from '../../utils/contexts/store';
import './cdrs.scss';
// Components
import Cdr from './Cdr';
import Loader from '../Loader';
import ErrorMessage from '../ErrorMessage';
import SearchInput from '../SearchInput';
// Materia
import Grid from '@mui/material/Unstable_Grid2';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import SelectCdrsListSize from './SelectCdrsListSize';

const Cdrs = () => {
  // TODO: uniquement sans BDD
  const navigate = useNavigate();

  const { fileId } = useParams();
  const {
    cdrs: [cdrs, setCdrs],
    filteredCdrs: [filteredCdrs],
    cdrsCompanies: [cdrsCompanies, setCdrsCompanies],
    files: [files],
  } = useContext(StoreContext)

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState(null);
  const [file, setFile] = useState(null);
  const [queryCompanyName, setQueryCompanyName] = useState("");
  const [queryCompanyReference, setQueryCompanyReference] = useState("");
  const [filteredCdrsCompanies, setFilteredCdrsCompanies] = useState(null);
  const [cdrsListSize, setCdrsListSize] = useState(25)
  
  const initializeCdrs = useCallback(() => {
    const fetchFile = async () => {
      try {
        const response = await axios.get(`/files/${fileId}`);
        setFile(response.data)
      } catch (error) {
        console.log(error);
        setErrorMsg('Une erreur inattendu s\'est produite lors de la récupération des cdr, veuillez recommencer après avoir vérifié votre connexion internet ou contacter le support technique')
      } finally {
        setIsLoading(false)
      }
    }
    // TODO: uniquement sans BDD
    // if (0 === files.length) {
    //   setIsLoading(true)
    //   fetchFile()
    // }
    // else {
    //   setFile(files.filter((file) => file.id === fileId)[0])
    // }
    if (0 === files.length) {
      navigate('/')
    }
    
    setFile(files.filter((file) => file.name === fileId)[0])
  }, [fileId, files]);

  /**
   * Permet d'obtenir les Cdr triés par company et type
   * 
   * @param {Object} newCdrs 
   */
  const createCdrCompanies = useCallback(() => {
    const currentCdrs = 0 !== filteredCdrs.length ? [ ...filteredCdrs ] : [ ...cdrs ];
    let result = [];
      
    currentCdrs.forEach((cdr) => {
      const lastDuration = result[cdr.companyName]?.[cdr.categoryName]?.duration ?? 0;
      const lastTotalDuration = result[cdr.companyName]?.['Total']?.duration ?? 0;
      const newDuration = cdr.duration;
      
      let lastCost = result[cdr.companyName]?.[cdr.categoryName]?.cost ?? "0";
      lastCost = 'number' !== typeof lastCost ? Number(lastCost.replace(',', '.')) : lastCost;
      let lastTotalCost = result[cdr.companyName]?.['Total']?.cost ?? "0";
      lastTotalCost = 'number' !== typeof lastTotalCost ? Number(lastTotalCost.replace(',', '.')) : lastTotalCost;
      const newCost = 'number' !== typeof cdr.cost ? Number(cdr.cost.replace(',', '.')) : cdr.cost;
  
      result[cdr.companyName] = {
        ...result[cdr.companyName],
        companyReference: cdr.companyReference,
        date: cdr.date,
        [cdr.categoryName] : {
          ...result[cdr.companyName]?.[cdr.categoryName],
          duration: lastDuration + newDuration,
          cost: lastCost + newCost,
        },
        Total: {
          ...result[cdr.companyName]?.['Total'],
          duration: lastTotalDuration + newDuration,
          cost: lastTotalCost + newCost,
        }
      }
    })

    const resultSorted = Object.fromEntries(
      Object.entries(result).sort((a, b) => a[0].localeCompare(b[0])
    ))

    return resultSorted;
  }, [cdrs, filteredCdrs])

  useEffect(() => {
    initializeCdrs()
  }, [initializeCdrs, fileId, files])

  // recupères la liste des files depuis la bdd si le state est vide
  useEffect(() => {
    const fetchCdrs = async () => {
      try {
        const response = await axios.get(`/cdrs?files=${fileId}`);
        createCdrCompanies(response.data['hydra:member']);
        setCdrs(response.data['hydra:member'])
      } catch (error) {
        console.log(error);
        setErrorMsg('Une erreur inattendu s\'est produite lors de la récupération des cdr, veuillez recommencer après avoir vérifié votre connexion internet ou contacter le support technique')
      } finally {
        setIsLoading(false)
      }
    }
    if (null !== file) {
      const localCdrsCompanies = JSON.parse(localStorage.getItem(`cdrsCompanies-${file?.name}`));

      if (localCdrsCompanies && 0 !== Object.keys(localCdrsCompanies).length) {
        setTimeout(() => {
          setCdrsCompanies(localCdrsCompanies)
          setIsLoading(false);
        }, 250);
      } else if (0 === cdrs.length) {
        fetchCdrs()
      } else {
        const createNewCdrCompanies = () => {
          try {
            const newCdrCompanies = createCdrCompanies();
            setCdrsCompanies(newCdrCompanies);
            if (undefined !== file?.name) {
              localStorage.setItem(`cdrsCompanies-${file.name}`, JSON.stringify(newCdrCompanies));
            }
          } catch (error) {
            console.log(error);
          } finally {
            setIsLoading(false);
          }
        }
        createNewCdrCompanies()
      }
    }
  }, [cdrs.length, createCdrCompanies, file, fileId, setCdrs, setCdrsCompanies]);

  /**
   * Retourne le mois et l'année précendent en français de la Date en parametre
   * 
   * @param {Date} date
   * @returns 
   */
  const getDate = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    const newDate = date.toLocaleDateString("fr-FR", {month: 'long', year: 'numeric'});

    return newDate.charAt(0).toUpperCase() + newDate.slice(1);
  }

  /**
   * Permet de changer de page depuis la barre de pagination
   * 
   * @param {JsEvent} e 
   * @param {string} page - la page selectionnée par l'utilisateur
   */
  const handleChangePagination = (e, page) => {
    setPage(page);
  }

  /**
   * Controle le champs de recherche par nom de société et filtre cdrsCompanies
   * 
   * @param {*} e 
   * @param {*} inputValue 
   */
  const handleOnChangeSearchCompanyName = (e, inputValue) => {
    setQueryCompanyName(inputValue || "")
    
    const filteredCdrsCompanies = Object.keys(cdrsCompanies)
      // filtre le tablau cdrsCompanies
      .filter((key) => key.toLowerCase().includes(inputValue.toLowerCase()))
      // reconstruit l'objet cdrsCompanies
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: cdrsCompanies[key]
        });
      }, {})
    ;

    setPage(1);
    setFilteredCdrsCompanies(filteredCdrsCompanies);
  }

  // si filteredCdrs est modifié, met à jour filteredCdrsCompanies
  useEffect(() => {
    if (null !== filteredCdrsCompanies) {
      const newFilteredCdrsCompanies = Object.fromEntries(
        Object.keys(filteredCdrsCompanies).map(
          // met à jour filteredCdrsCompanies avec les données de cdrsCompanies 
          // qui elles ont été mises à jour lors de filterNumber
          (key) => [key, cdrsCompanies[key]]
        )
      )
  
      setFilteredCdrsCompanies(newFilteredCdrsCompanies)
    }
  }, [filteredCdrs, cdrsCompanies, setFilteredCdrsCompanies]) // TODO: utilisation de useReducer a faire pour les filtres

  /**
   * Controle le champs de recherche par numéro de dossier et filtre cdrsCompanies
   * 
   * @param {*} e 
   * @param {*} inputValue 
   */
  const handleOnChangeSearchCompanyReference = (e, inputValue) => {
    setQueryCompanyReference(inputValue || "")

    const filteredCdrsCompanies = Object.keys(cdrsCompanies)
      // filtre le tablau cdrsCompanies
      .filter((key) => cdrsCompanies[key].companyReference.toLowerCase().includes(inputValue.toLowerCase()))
      // reconstruit l'objet cdrsCompanies
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: cdrsCompanies[key]
        });
      }, {})
    ;

    setPage(1);

    setFilteredCdrsCompanies(filteredCdrsCompanies);
  }
  
  /**
   * Permet de reset les states nécessaires au fonctionnment du SearchInput
  */
 const resetSearchInput = () => {
    setQueryCompanyName('');
    setQueryCompanyReference('');
    setFilteredCdrsCompanies(cdrsCompanies);
  }

  return (
    <div className="cdrs">
      { isLoading
        ? <Loader size={'400px'} message={'Chargement des fichiers en cours cela peut prendre plusieurs minutes'} />
        : (
          errorMsg 
            ? (
              <ErrorMessage errorMsg={errorMsg} setErrorMsg={setErrorMsg} restartAction={initializeCdrs} />
            )
            : (
              ( 0 !== cdrsCompanies.length && (
                <>
                  {/* date des cdrs en cours */}
                  <Typography variant="h1" className="cdrs_date">
                    { file && getDate(new Date(file.date)) }
                  </Typography>
                  {/* filtre les cdrs */}
                  <SearchInput 
                    cdrsCompanies={cdrsCompanies}
                    handleOnChangeCompanyName={handleOnChangeSearchCompanyName}
                    handleOnChangeCompanyReference={handleOnChangeSearchCompanyReference}
                    queryCompanyName={queryCompanyName}
                    queryCompanyReference={queryCompanyReference}
                    resetInput={resetSearchInput}
                  />
                  {/* affiche les cdr par card */}
                  <Grid container spacing={3} className="cdrs_container">
                    {/* pagination top & permet de defenir le nombre de card par page */}
                    <Stack spacing={2} className="cdrs_pagination cdrs_pagination--top">
                      <Pagination className="cdrs_pagination--element" count={Math.ceil(Object.keys(filteredCdrsCompanies ?? cdrsCompanies).length / cdrsListSize)} page={page} onChange={handleChangePagination} />
                      <SelectCdrsListSize cdrsListSize={cdrsListSize} setCdrsListSize={setCdrsListSize} setPage={setPage} />
                    </Stack>
                    {/*  Liste des cards */}
                    {
                      Object.entries(filteredCdrsCompanies ?? cdrsCompanies).slice(cdrsListSize * (page - 1), (cdrsListSize * page) - 1).map(([companyName, value]) => (
                        <Grid key={companyName}> 
                          <Cdr cdr={value} companyName={companyName} file={file} />
                        </Grid>
                      ))
                    }
                  </Grid>
                  {/* pagination bottom */}
                  <Stack spacing={2} className="cdrs_pagination cdrs_pagination--bottom">
                    <Pagination count={Math.ceil(Object.keys(filteredCdrsCompanies ?? cdrsCompanies).length / cdrsListSize)} page={page} onChange={handleChangePagination} />
                  </Stack>
                </>
              ))
            )
        )
      }
    </div>
  )
}

export default Cdrs;
