import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { StoreContext  } from '../../utils/contexts/store';
// Materia
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import {IconButton, Box  } from '@mui/material';

const CdrTitle = ({ fileId, companyReference, companyName }) => {

  const {
    isMerged: [isMerged, setIsMerged],
  } = useContext(StoreContext)

  const navigate = useNavigate();

  const handleOnClickGroupByCompanyReference = () => {
    const commonCompanyReference = companyReference.split('-')[0]
    // le state sera commun pour toute les société qui ont la meme racine du
    setIsMerged({ ...isMerged, [commonCompanyReference]: !isMerged?.[commonCompanyReference] });
    navigate(`/imports/${fileId}/cdrs/${commonCompanyReference}`);
  }

  const handleOnClickUnGroupByCompanyReference = () => {
    setIsMerged({ ...isMerged, [companyReference]: !isMerged?.[companyReference] });
    navigate(`/imports/${fileId}/cdrs`);
  }

  return (
    <Box className="cdrTitle" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
      {companyName}
      {
        isMerged?.[companyReference]
          ? (
            <IconButton
              aria-label="Degrouper"
              onClick={handleOnClickUnGroupByCompanyReference}
              title="Degrouper"
              color='primary'
            >
              <LinkOffIcon fontSize="large" title="Degrouper"/>
            </IconButton>
          )
          : (
            <IconButton
              aria-label="Regrouper par référence"
              onClick={handleOnClickGroupByCompanyReference}
              title="Regrouper par référence"
              color='primary'
            >
              <LinkIcon fontSize="large"/>
            </IconButton>
          )
      }
    </Box>
  )
}

export default CdrTitle