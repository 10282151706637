import React from 'react'
import './loader.scss'
// Materia
import { Typography } from '@mui/material';

const Loader = ({size, message}) => {
  return (
    <div className="loader">
      { message && (
        <Typography variant="h4" className="loader_message">
          { message }
        </Typography>
      )}
      <div className="lds-dual-ring" style={{width: size, height: size}}></div>
    </div>
  )
}

export default Loader