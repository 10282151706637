import React, { useCallback, useState, useEffect } from 'react'
import { axiosPrivate as axios } from '../../utils/axios/axiosPrivate';
import { useNavigate } from 'react-router-dom'
import './landing.scss';
// Components
import Loader from '../Loader';
import ErrorMessage from '../ErrorMessage';

const Landing = () => {
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate();

  /**
   * Import le fichier du mois en cours et affiche les cdr ou propose une nouvelle importation
   */
  const fetchFiles = useCallback(async () => {
    setIsLoading(true);
    try {
      // créer un intervale de date pour obtenir le fichier du mois en cours
      const oneMonthAgo = new Date();
      // oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
      const oneMonthAgoToLocaleDateString = oneMonthAgo.toLocaleDateString("en-US")

      const twoMonthAgo = new Date();
      twoMonthAgo.setMonth(twoMonthAgo.getMonth() - 1);
      const twoMonthAgoToLocaleDateString = twoMonthAgo.toLocaleDateString("en-US")
          
      const response = await axios.get(`/files?date[after]=${twoMonthAgoToLocaleDateString}&date[before]=${oneMonthAgoToLocaleDateString}`)

      // /import -> nouvelle importation; /imports/fileId/cdrs -> cdr du fichier du mois
      0 === response.data['hydra:member'].length
        ? navigate('/import')
        : navigate(`/imports/${response.data['hydra:member'][0].id}/cdrs`)

    } catch (error) {
      console.log(error);
      setErrorMsg('Une erreur inattendu s\'est produite, veuillez recommencer après avoir vérifié votre connexion internet ou contacter le support technique')
    } finally {
      setIsLoading(false);
    }
  }, [navigate])

  useEffect(() => {
    setIsLoading(true);

    // TODO: uniquement sans BDD
    // setTimeout(() => {
    //   fetchFiles()
    // }, 1500)
    // TODO: uniquement sans BDD
    navigate('/import')
  }, [fetchFiles])

  return (
    <div className="landing">
      { isLoading 
        ? (
          <Loader size={'400px'} message={'Bienvenue sur Avelia Facturation !'} />
        )
        : ( errorMsg && (
          <ErrorMessage errorMsg={errorMsg} setErrorMsg={setErrorMsg} restartAction={fetchFiles} />
        ))
      }
    </div>
  )
}

export default Landing