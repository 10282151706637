import React, { useState } from 'react'
import './importIcon.scss';
// Components
import Loader from '../Loader';
import ImportIconBody from './ImportIconBody';
import ImportIconHeader from './ImportIconHeader';
// Materia
import { Box } from '@mui/material';

const ImportIcon = () => {
  const [file, setFile] = useState(null)
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const [isImported, setIsImported] = useState(false);
  
  return (
    <div className="import_icon">
      <ImportIconHeader file={file} errorMsg={errorMsg} />
    {
      <Box className="import_icon_box" component="div" sx={{ border: '10px dashed', borderColor: 'primary.main' }} >
        {
          isLoading 
            ? (
              <Loader size={'175px'} />
            ) 
            : (
              <>
                <ImportIconBody 
                  file={file}
                  setFile={setFile}
                  setIsLoading={setIsLoading}
                  setErrorMsg={setErrorMsg}
                  isImported={isImported}
                  setIsImported={setIsImported}
                />
              </>
            )
        }
      </Box>
    }

  </div>
  )
}

export default ImportIcon;