import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import StoreProvider from '../../utils/contexts/store';
import './App.scss';
// Components
import Header from '../Header';
import Import from '../Import';
import Cdrs from '../Cdrs';
import GroupedCdrs from '../Cdrs/GroupedCdrs';
import ImportList from '../ImportList';
import Landing from '../Landing';
import RequireAuth from '../Auth';
import Login from '../Login';
import Logout from '../Logout';

function App() {
  return (
    <Router>
    <StoreProvider>
    <div className="App">
      <Header />
      <div className="container">
        <Routes>
          <Route exact path='/' element={<RequireAuth><Landing /></RequireAuth>} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/logout' element={<Logout />} />
          <Route exact path="/import" element={<RequireAuth><Import  /></RequireAuth>} />
          <Route exact path="/imports" element={<RequireAuth><ImportList /></RequireAuth>} />
          <Route exact path="/imports/:fileId/cdrs" element={<RequireAuth><Cdrs /></RequireAuth>} />
          <Route exact path="/imports/:fileId/cdrs/:companyReference" element={<RequireAuth><GroupedCdrs /></RequireAuth>} />
          
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </div>
    </StoreProvider>
    </Router>
  );
}

export default App;
