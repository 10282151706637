import React from 'react'

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

const SelectCdrsListSize = ({ cdrsListSize, setCdrsListSize, setPage }) => {

  const handleChange = (event) => {
    setCdrsListSize(event.target.value);
    setPage(1);
  };

  return (
    <div className="selectCdrsListSize">
      <FormControl  variant="standard" sx={{ m: 1, minWidth: 85 }}>
        <InputLabel id="demo-simple-select-filled-label">Nb de sociétés</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={cdrsListSize}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'Without label' }}
          label="Nb de sociétés"
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}

export default SelectCdrsListSize