import React, { useContext, useState } from 'react'
import { axiosPrivate as axios } from '../../utils/axios/axiosPrivate';
import { StoreContext  } from '../../utils/contexts/store';
import { Link } from 'react-router-dom';
// Components
import Loader from '../Loader';
// Materia
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';

const ImportListTable = ({ setErrorMsg }) => {
  const {
    files: [files, setFiles],
    cdrs: [cdrs, setCdrs],
  } = useContext(StoreContext)

  const [isDeleting, setIsDeleting] = useState(false)

  /**
   * Crée le composant permetant la suppresion de l'import
   * 
   * @param {string} id identifiant du File à supprimer
   * @returns 
   */
    const createDeleteButton = (id) => (
      <IconButton aria-label="delete" size="medium" onClick={ () => handleOnclickDeleteImport(id) }>
        <DeleteIcon fontSize="inherit" color="error"/>
      </IconButton>
    )
  
  /**
   * Créer un lien vers les cdr du fichier
   * 
   * @param {string} name - nom du fichier
   * @param {string} id - identifiant du fichier
   * @returns 
   */
  const createNameLink = (name, id) => {
    return (
      <Link to={`/imports/${id}/cdrs`} style={{ color: "inherit" }}>
        <Typography
          noWrap
          component="a"
          sx={{
            fontSize: '1rem',
            fontWeight: 700,
            color: 'inherit',
          }}
        >
          {name}
        </Typography>
      </Link>
    )
  }

  /**
   * Retourne le mois et l'anncée précendent en français de la Date en parametre
   * 
   * @param {Date} date
   * @returns 
   */
  const getDate = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    const newDate = date.toLocaleDateString("fr-FR", {month: 'long', year: 'numeric'});

    return newDate.charAt(0).toUpperCase() + newDate.slice(1);
  }

  // materia table
  const createData = (name, date, id) => ({ name, date, id })

  const rows = files.map(
    ({ name, date, id }) => createData(
      createNameLink(name, id),
      getDate(new Date(date)),
      createDeleteButton(id),
    )
  )
  
  // update bdd
  
  /**
   * Permet la suppression d'un fichier et de ses cdr en bdd
   * 
   * @param {string} id identifiant du File à supprimer
   */
  const deleteFile = async (id) => {
    setIsDeleting(true)
    try {
      await axios.delete(`/files/${id}`)
      setFiles(files.filter((file) => file.id !== id));
      setCdrs(cdrs.filter((cdr) => cdr.file !== id));
    } catch (error) {
      console.log(error);
      setErrorMsg('Une erreur inattendu s\'est produite lors de la suppression de l\'import, veuillez recommencer après avoir vérifié votre connexion internet ou contacter le support technique');
    } finally {
      setIsDeleting(false);
    }
  }

  // JS events
  const handleOnclickDeleteImport = (id) => {
    deleteFile(id)
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ maxWidth: 850 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Nom</TableCell>
            <TableCell align="left">Date</TableCell>
            <TableCell align="center">Supression</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { 0 !== files.length && (rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="left">{row.date}</TableCell>
              <TableCell align="center">{ isDeleting ? <Loader size={'40px'}/> : row.id }</TableCell>
            </TableRow>
          )))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ImportListTable