import React, { useState, useContext, useCallback } from 'react'

import { StoreContext  } from '../../utils/contexts/store';
// Components
import CdrTable from './CdrTable';
import CdrFilter from './CdrFilter';
import CdrTitle from './CdrTitle'
// Materia 
import { Button, Card, CardHeader, CardContent } from '@mui/material';

const Cdr = ({
    cdr,
    companyName,
    file,
  }) => {

  const {
    cdrs: [cdrs],
    callNumbersChecked: [callNumbersChecked],
    filteredCdrs: [filteredCdrs, setFilteredCdrs],
  } = useContext(StoreContext)

  const [isFiltered, setIsFiltered] = useState(false);
  
  const emptyTypes  = {
    'National': {
      duration: 0,
      cost: 0,
    },
    'National mobile': {
      duration: 0,
      cost: 0,
    },
    'International': {
      duration: 0,
      cost: 0,
    },
    'International mobile': {
      duration: 0,
      cost: 0,
    },
    'Numéros spéciaux': {
      duration: 0,
      cost: 0,
    },
  }

  const newCdr = {
    ...emptyTypes ,
    ...cdr,
  }
  
  // si le numéro a été décoché sa duration et son cout seront égale à 0 dans filteredCdrs
  const filterCdrs = useCallback(async () => {
    // liste de tous les numéros décoché
    const uncheckedCallNumbers = Object.values(callNumbersChecked)
      .map(
        (callNumberChecked) => Object.entries(callNumberChecked),
      ).flat()
      .filter(
        ([callNumber, isChecked]) => !isChecked,
      )
      .map(
        ([callNumber, isChecked]) => callNumber,
      )
    ;

    // met à jour le prix et la durée des numéros décoché
    const newCdrs = await cdrs.map(
      (cdr) => uncheckedCallNumbers.includes(cdr.callNumber)
        ? ({
            ...cdr,
            duration: 0,
            cost: 0,
        })
        : ({
         ...cdr 
        })
      )
    
    setFilteredCdrs(newCdrs);
  }, [cdrs, setFilteredCdrs, callNumbersChecked])

  // affiche/cache le filtrage par numéro
  const handleOnClickFilter = () => {
    setIsFiltered(!isFiltered)
    if (isFiltered) {
      localStorage.removeItem(`cdrsCompanies-${file.name}`)
      filterCdrs();
    }
  }

  return (
    <Card sx={{ width: 475 }} >
      <CardHeader
        sx={{ borderBottom: 'solid #AAA 1px'}}
        title={<CdrTitle fileId={file.name} companyReference={cdr.companyReference} companyName={companyName} />}
        subheader={cdr.companyReference}
      />
      <CardContent  sx={{ padding: 0, paddingBottom: 0 }} className={`cdrs_card-content`}>
        {
          isFiltered
            ? (
              <CdrFilter companyCardName={companyName} />
            )
            : (
              <CdrTable cdrsCompany={newCdr} />
            )
        }
      </CardContent>
      <CardContent sx={{ textAlign: 'center', borderTop: 'solid 1px black' }} className="cdrs_card-footer">
        <Button onClick={() => handleOnClickFilter(companyName)}> Filtrer par numéro </Button>
      </CardContent>
    </Card>
  )
}

export default React.memo(Cdr)