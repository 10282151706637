import React, { useEffect, useCallback, useContext, useState } from 'react'
import { axiosPrivate as axios } from '../../utils/axios/axiosPrivate';
import { StoreContext  } from '../../utils/contexts/store';
// Components
import ImportListTable from './ImportListTable';
// Materia
import { Typography } from '@mui/material';

const ImportList = () => {
  const {
    files: [files, setFiles],
  } = useContext(StoreContext)

  const [errorMsg, setErrorMsg] = useState("");
  
  // recupères la liste des files depuis la bdd si le state est vide
  const fetchFiles = useCallback(async () => {
    try {
      const response = await axios.get('/files')
      setFiles(response.data['hydra:member']);
    } catch (error) {
      console.log(error);
      setErrorMsg('Une erreur inattendu s\'est produite lors de la récupération des imports, veuillez recommencer après avoir vérifié votre connexion internet ou contacter le support technique')
    }
  }, [setFiles])

  useEffect(() => {
    if (0 === files.length) {
      fetchFiles()
    }
  }, [fetchFiles, files.length])
  
  return (
    <div className='import_list'>
      <Typography variant="h1" className="import_date">
        Imports
      </Typography>
      <div className="import_container">
        {
          errorMsg && (
            <Typography component="div" color="error">
              { errorMsg }
            </Typography>
          )
        }

        <ImportListTable setErrorMsg={setErrorMsg} />
      </div>
    </div>
  )
}

export default ImportList