import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Papa from 'papaparse';
import JSZip from 'jszip';
import { axiosPrivate as axios } from '../../utils/axios/axiosPrivate';
import { StoreContext  } from '../../utils/contexts/store';

// Materia
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FolderIcon from '@mui/icons-material/Folder';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button, IconButton, Box } from '@mui/material';

const ImportIconBody = ({
  setIsLoading,
  file,
  setFile,
  setErrorMsg,
  isImported,
  setIsImported,
}) => {
  const navigate = useNavigate();
  const {
    files: [files, setFiles],
    cdrs: [cdrs, setCdrs],
  } = useContext(StoreContext)

  const [isDroped, setIsDroped] = useState(false);

  /**
   * Récupère une date au string Fr au format date En
   * 
   * @param {string} frenchDate 
   * @returns {Date}
   */
  const formatToEnglishDate = (frenchDate) => {
    const [day, mounth, year] = frenchDate.split('/');

    return new Date(mounth + '/' + day + '/' + year)
  }
  
  /**
   * Reinitialise les state liée a l'import et affiche un message d'erreur
   * 
   * @param {string} reason Message d'erreur à afficher
   */
  const cancelImportZip = (reason) => {
    setErrorMsg(reason)
    setIsLoading(false);
    setIsImported(false);
    setFile(null);
  }

  /**
   * Extrait les données du fichier importer par l'utilisateur
   * 
   * @param {File} file fichier importé par l'utilisateur
   */
  const extractFile = (file) => {
    // reinitialise les states a chaque nouvel extraction
    setIsImported(true);
    setErrorMsg('');
    setIsLoading(true)
    setFile(null);
    
    if (isValidZipFile(file)) {
      unzip(file)
    }
  }

  /**
   * Dezip le file, extrait les données depuis le csv puis hydrate cdrs et file
   * 
   * @param {File} file le fichier zip qui va être extrait 
   */
  const unzip = async (file) => {
    const zip = new JSZip();
    const extractedFiles = await zip.loadAsync(file);

    if (isValidExtractedFile(extractedFiles)) {
      extractedFiles.forEach(async (relativePath, data) => {
        const content = await data.async("string");
        setFile(
          {
            name: data.name,
            date: data.date,
            updateAt:new Date(),
          }
        );
        
        // TODO: uniquement sans BDD
        setFiles(
          [{
            name: data.name,
            date: data.date,
            updateAt:new Date(),
          }]
        );

        Papa.parse(content, {
          header: true,
          skipEmptyLines: true,
          fastMode: true,
          complete: function (csvData) {
            if (isValidCsv(csvData)) {
              const cdrs = [];
              // csvData.data.slice(0, 5).forEach( (result) => {
              csvData.data.forEach( (result) => {
                cdrs.push({
                  callNumber: result['Numero facture'].trim(),
                  duration: parseInt(result['Duree de l\'appel']),
                  cost: result['Cout client'],
                  date: formatToEnglishDate(result['Date d\'appel']),
                  companyName: result['Nom client'].trim(),
                  companyReference: result['Ref client'],
                  categoryName: result['Categorie de destination'],
                })    

              })                
              setIsLoading(false);
              setCdrs(cdrs);
            }
          }
        });
      });
    }
  }

  // update bdd

  /**
   * Crée les Cdr en base de donnée.
   * Redirige vers /cdrs si ok ou affiche un message d'erreur 
   * 
   * @param {string} fileUuid identifiant du file auquel les cdr sont ratachées
   */
  const createCdrs = async (fileUuid) => {
    // rajoute le file aux Cdr
    const newCdrs = cdrs.map(
      (cdr) => ({ ...cdr, file: `/api/files/${fileUuid}` })
    )

    try {
      const response = await axios.post('/cdrs', JSON.stringify({'cdrs': newCdrs}));
      setCdrs(response.data.map(JSON.parse));
      navigate(`/imports/${fileUuid}/cdrs`)
      setErrorMsg(null);
    } catch (error) {
      console.error(error);
      cancelImportZip('Une erreur inatendu s\'est produite')
    }
  }
  
  /**
   * Rajoute un file en BDD
   * Si ok rajoute les CDR, sinon affiche un message d'erreur
   * 
   * @param {object} newFile 
   */
  const createFile = async (newFile) => {
    
    setIsLoading(true);
    try {
      const response = await axios.post('/files', newFile);
      localStorage.removeItem(`cdrsCompanies-${file.name}`);
      
      setFiles([
          ...files,
          {
            ...file,
            date: response.data.date,
            id: response.data.id,
          }
        ]
      )
      
      await createCdrs(response.data.id)
    } catch (error) {
      console.log(error);
      const [{ code, message }] = error.response.data.violations;

      // si le nom du fichier existe déjà
      if ('23bd9dbf-6b9b-41cd-a99e-4844bcf3077f' === code) {
        cancelImportZip(message)
      }
      else {
        cancelImportZip('Une erreur inatendu s\'est produite')
      }
    } finally {
      setIsLoading(false);
    }
  }
  
  
  // Validators
  
  /**
   * Verifie si une colonne est présente dans un fichier csv
   * 
   * @param {string} csvColumns Noms des colonnes du fichier csv importé
   * @param {string} columnName Nom de la colone qui soit être présent dans le fichier csv
   * @returns 
   */
  const isValidCsvColumn = (csvColumns, columnName) => {
    if (!csvColumns.includes(columnName)) {
      cancelImportZip(`La colonne ${columnName} doit être présente dans le csv`);

      return false;
    }

    return true;
  }
  
  /**
   * Verifie si le fichier csv est conforme
   * 
   * @param {object} csv 
   * @returns 
   */
  const isValidCsv = (csv) => {

    return (
      isValidCsvColumn(csv.meta.fields, 'Numero facture') &&
      isValidCsvColumn(csv.meta.fields, 'Duree de l\'appel') &&
      isValidCsvColumn(csv.meta.fields, 'Cout client') &&
      isValidCsvColumn(csv.meta.fields, 'Date d\'appel') &&
      isValidCsvColumn(csv.meta.fields, 'Nom client') &&
      isValidCsvColumn(csv.meta.fields, 'Ref client') &&
      isValidCsvColumn(csv.meta.fields, 'Categorie de destination')
    )
  }
  
  /**
   * Verifie si le fichier présent à l'intérieur du zip est conforme
   * 
   * @param {object} extractedFiles
   * 
   * @returns {boolean}
   */
  const isValidExtractedFile = (extractedFiles) => {
    if (1 < Object.keys(extractedFiles.files).length) {
      cancelImportZip(`Il ne doit y avoir que le fichier au format csv à l'interieur du zip`);

      return false;
    }
    
    if ('.csv' !== Object.values(extractedFiles.files)[0].name.slice(-4)) {
      cancelImportZip(`Le fichier présent à l'intérieur du zip doit être au format csv`);

      return false;
    }

    return true;
  }
  
  /**
   * Verifie si le fichier zip importer par l'utilisateur est conforme
   * 
   * @param {File} zipFile
   * 
   * @returns {boolean}
   */
  const isValidZipFile = (zipFile) => {
    if ('application/x-zip-compressed' !== zipFile.type) {
      cancelImportZip(`Le fichier ${file.name} n'est pas au format zip`);
      ;
      return false;
    }

    return true
  }

  // JS events
  const handleOnDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    extractFile(e.dataTransfer.files[0])
  }

  const handleOnChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    extractFile(e.target.files[0])
    // reset de la valeur pour pouvoir resoumettre un fichier en cas d'annulation
    e.target.value = ''
  }

  const handleOnDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  }

  const handleOnclickDeleteImport = () => {
    cancelImportZip('')
  }

  const handleOnclickSaveImport = () => {
    setErrorMsg('Sauvegarde des fichiers en cours, cela peut prendre jusqu\'a 10 minutes')
    // TODO: uniquement sans BDD
    setIsLoading(true)
    // createFile(file);
    navigate(`/imports/${file.name}/cdrs`)
  }

  const handleOnDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    setIsDroped(true);
  }

  const handleOnDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDroped(false);
  }

  return (
    <>
      <>
        {
          isDroped || isImported
            ? (
              <FolderIcon className="import_icon_svg" color="primary" onDrop={ handleOnDrop } onDragOver={ handleOnDragOver } onDragLeave={ handleOnDragLeave } />
            )
            : (
              <IconButton className="import_icon_button" sx={{ margin: 0, padding: 0 }} color="primary" aria-label="upload picture" component="label" onDragEnter={ handleOnDragEnter}>
                <input hidden type="file" onChange={ handleOnChange } />
                <DriveFolderUploadIcon className="import_icon_svg" color="primary" />
              </IconButton>
            )
        }
      </>
      <>
        {
          isImported && (
            <Box className="import_icon_save">
              <Button variant="outlined" onClick={ handleOnclickSaveImport } >Commencer l'import</Button>
              <IconButton aria-label="delete" size="medium" onClick={ handleOnclickDeleteImport }>
                <CancelIcon fontSize="inherit" color="error"/>
              </IconButton>
            </Box>
          )
        }
      </>
    </>
  )
}

export default ImportIconBody