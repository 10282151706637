import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";

const RequireAuth = ({ children }) => {
  const userIsLogged = localStorage.getItem('user');
  const navigate = useNavigate()

  useEffect(() => {
    if (!userIsLogged) {
      navigate('/login');
    }
  }, [navigate, userIsLogged])

  return children;
};

export default RequireAuth;