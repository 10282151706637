import React from 'react'

// Materia
import { Typography } from '@mui/material';

const ImportIconHeader = ({
  errorMsg,
  file,
}) => {
  return (
    <>
      { errorMsg 
        ? (
          <Typography component="div" className="import_icon_header import_icon_header--error" color="error">
            { errorMsg }
          </Typography>
        ) : file && (
          <Typography component="div" className="import_icon_header import_icon_header--filename">
            { file.name }
          </Typography>
        )
      }
    </>
  )
}

export default ImportIconHeader