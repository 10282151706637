// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

export const getDb = () => {
  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyChYaxN6A1EbkIUCYIqfjH0pMP033dNdmI",
    authDomain: "avelia-facturation-4daf0.firebaseapp.com",
    projectId: "avelia-facturation-4daf0",
    storageBucket: "avelia-facturation-4daf0.appspot.com",
    messagingSenderId: "1007874668026",
    appId: "1:1007874668026:web:4a4ff2b3472df742416043"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  return getFirestore(app)
}
