import React from 'react'

// Materia
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';

const CdrTable = ({ cdrsCompany }) => {
  // materia table
  const createData = (type, duration, cost) => ({ type, duration, cost })

  // trie les clé de cdrsCompany par odre alphabétique, le but étant d'avoir Total en fin de liste
  let cdrsCompanyArray = Object.entries(cdrsCompany);
  cdrsCompanyArray.sort((a,b) => a[0].localeCompare(b[0]))

  const rows = cdrsCompanyArray.map(
    ([ type, { duration, cost } ]) => {
      if ('companyReference' === type || 'date' === type) {
        return null
      }

      return createData(
        type,
        duration,
        cost,
      )
    }
  ).filter((cdr) => null !== cdr)
  // transforme les secondes en minute
  const secondToMinute = (second) => Math.round((second / 60) * 100) / 100;
  // arrondie le cout a 2 chriffres apres la virgule
  const arrond = (number) => +(Math.round(number + "e+" + 2) + "e-" + 2);

  return (
    <TableContainer>
      <Table sx={{ maxWidth: '100%' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center">
              <Typography fontSize="1.2rem" variant="body1">Durée</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontSize="1.2rem" variant="body1">Coût</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { 0 !== cdrsCompany.length && (rows.map((row) => (
            <TableRow
              key={row.type}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Typography fontSize="1.2rem" variant="body2">{row.type}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontSize="1.2rem" variant="body2">{secondToMinute(row.duration)}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontSize="1.2rem" variant="body2">{arrond(row.cost)}</Typography>
              </TableCell>
            </TableRow>
          )))}
        </TableBody>
      </Table>
    </TableContainer>
    )
}

export default React.memo(CdrTable)