import React from 'react';
import './errorMessage.scss';

// Materia
import { Typography } from '@mui/material';
import { Button } from '@mui/material';

const ErrorMessage = ({ errorMsg, setErrorMsg, restartAction }) => {
  
  /**
   * Permet de relancer le chargement de page
   */
  const handleOnClickRestart = () => {
    setErrorMsg(null);
    restartAction();
  }

  return (
    <div className="errorMessage">
      <Typography className="errorMessage_message" variant="h4">
        { errorMsg }
      </Typography>
      <Button className="errorMessage_button" variant="outlined" size='large' onClick={ handleOnClickRestart }>
        Recomencer
      </Button>
    </div>
  )
}

export default ErrorMessage