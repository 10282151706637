import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { StoreContext  } from '../../utils/contexts/store';
// Materia
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import GroupedCdr from './GroupedCdr';

const GroupedCdrs = () => {
  const { companyReference, fileId } = useParams();
  
  const {
    filteredCdrs: [filteredCdrs],
    cdrsCompanies: [cdrsCompanies],
    cdrs: [cdrs],
  } = useContext(StoreContext)
 
  const [mergedCdrsCompanies, setMergedCdrsCompanies] = useState(null);
  const [filteredCdrsCompanies, setFilteredCdrsCompanies] = useState(null);
  
  /**
   * Permet d'obtenir les Cdr triés par company et type
   * 
   * @param {Object} currentCdrs 
   */
    const createCdrCompanies = useCallback((currentCdrs) => {
      let result = [];

      currentCdrs
        .filter(
          (cdr) => cdr.companyReference.includes(companyReference)
        )
        .forEach((cdr) => {
          const lastDuration = result[cdr.companyName]?.[cdr.categoryName]?.duration ?? 0;
          const lastTotalDuration = result[cdr.companyName]?.['Total']?.duration ?? 0;
          const newDuration = cdr.duration;
          
          let lastCost = result[cdr.companyName]?.[cdr.categoryName]?.cost ?? "0";
          lastCost = 'number' !== typeof lastCost ? Number(lastCost.replace(',', '.')) : lastCost;
          let lastTotalCost = result[cdr.companyName]?.['Total']?.cost ?? "0";
          lastTotalCost = 'number' !== typeof lastTotalCost ? Number(lastTotalCost.replace(',', '.')) : lastTotalCost;
          const newCost = 'number' !== typeof cdr.cost ? Number(cdr.cost.replace(',', '.')) : cdr.cost;
      
          result[cdr.companyName] = {
            ...result[cdr.companyName],
            companyReference: cdr.companyReference,
            date: cdr.date,
            [cdr.categoryName] : {
              ...result[cdr.companyName]?.[cdr.categoryName],
              duration: lastDuration + newDuration,
              cost: lastCost + newCost,
            },
            Total: {
              ...result[cdr.companyName]?.['Total'],
              duration: lastTotalDuration + newDuration,
              cost: lastTotalCost + newCost,
            }
          }
        })
      ;

      return Object.fromEntries(
        Object.entries(result).sort((a, b) => a[0].localeCompare(b[0])
      ))
    }, [companyReference])

  useEffect(() => {
    if (filteredCdrs) {
      const currentCdrs = 0 !== filteredCdrs.length ? [ ...filteredCdrs ] : [ ...cdrs ];
      const newCdrsCompanies = createCdrCompanies(currentCdrs);
      localStorage.setItem(`cdrsCompanies-${fileId}`, JSON.stringify({...cdrsCompanies, ...newCdrsCompanies}));
      setFilteredCdrsCompanies(newCdrsCompanies);
    }
  }, [cdrs, filteredCdrs, createCdrCompanies, cdrsCompanies, fileId])

  /**
   * Permet de regrouper les filteredCdrsCompanies en un mergedCdrsCompanies
   * 
   * @param {Object} currentFilteredCdrsCompanies 
   */
  const mergeReferenceCompanies = useCallback((currentFilteredCdrsCompanies) => {
    const newFilteredCdrsCompanies = Object.entries(currentFilteredCdrsCompanies)
      .reduce(
        (obj, acc) => {
          const [objCompanyName, objCdrValue] = obj;
          const [accCompanyName, accCdrValue] = acc;
          
          const newCompanyName = undefined !== objCompanyName 
            ? `${objCompanyName}, ${accCompanyName}`
            : accCompanyName
          ;

          const getNewValue = (location, type) => (
            undefined !== objCdrValue?.[location]?.[type]
              ? objCdrValue?.[location]?.[type] + (accCdrValue?.[location]?.[type] ?? 0)
              : accCdrValue?.[location]?.[type] ?? 0
          )
            
          const newCdrValue = {
            ...objCdrValue,
            companyReference: undefined !== objCdrValue?.companyReference 
              ? `${objCdrValue.companyReference}, ${accCdrValue.companyReference}`
              : accCdrValue.companyReference
            ,
            'National': {
              duration: getNewValue('National', 'duration'),
              cost: getNewValue('National', 'cost'),
            },
            'National mobile': {
              duration: getNewValue('National mobile', 'duration'),
              cost: getNewValue('National mobile', 'cost'),
            },
            'International': {
              duration: getNewValue('International', 'duration'),
              cost: getNewValue('International', 'cost'),
            },
            'International mobile': {
              duration: getNewValue('International mobile', 'duration'),
              cost: getNewValue('International mobile', 'cost'),
            },
            'Numéros spéciaux': {
              duration: getNewValue('Numéros spéciaux', 'duration'),
              cost: getNewValue('Numéros spéciaux', 'cost'),
            },
            'Total': {
              duration: getNewValue('Total', 'duration'),
              cost: getNewValue('Total', 'cost'),
            },
          }
          return [newCompanyName, newCdrValue];
        },
        [],
      )
    ;

    return Object.fromEntries([newFilteredCdrsCompanies]);
  }, [])

  useEffect(() => {
    if (filteredCdrsCompanies) {
      const newMergedCdrsCompanies = mergeReferenceCompanies(filteredCdrsCompanies)
      setMergedCdrsCompanies(newMergedCdrsCompanies)
    }
  }, [filteredCdrsCompanies, mergeReferenceCompanies])


  return (
    <div className="grouped-cdrs">
      <Typography variant="h1" className="cdrs_date">
        { companyReference }
      </Typography>
      <Grid container spacing={3} className="cdrs_container">
        {/* La card avec les données merge */}
        <Grid>
        { mergedCdrsCompanies && (
          <GroupedCdr cdr={Object.values(mergedCdrsCompanies)[0]} companyName={Object.keys(mergedCdrsCompanies)[0]} fileId={fileId} companyReferenceOrigin={companyReference} />
        )}
        </Grid>
      </Grid>
    </div>
  )
}
        

export default GroupedCdrs