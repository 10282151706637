import axios from "axios";

import { memoizedRefreshToken } from "./refreshToken";

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api`;
// declanche une CORS
// axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  async (config) => {
    const { token } = JSON.parse(localStorage.getItem("session"));

    if (token) {
      config.headers = {
        ...config.headers,
        'Accept': 'application/ld+json',
        'Content-Type': 'application/ld+json',
        authorization: `Bearer ${token}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const { token } = await memoizedRefreshToken();

      if (token) {
        config.headers = {
          ...config.headers,
          'Accept': 'application/ld+json',
          'Content-Type': 'application/ld+json',
          authorization: `Bearer ${token}`,
        };
      }

      return axios(config);
    }
    return Promise.reject(error);
  }
);

export const axiosPrivate = axios;