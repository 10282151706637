import { useState, createContext } from 'react';

export const StoreContext = createContext(null)

const StoreProvider  = ({ children }) => {
  const [cdrs, setCdrs] = useState([]);
  const [filteredCdrs, setFilteredCdrs] = useState([]);
  const [files, setFiles] = useState([]);
  const [cdrsCompanies, setCdrsCompanies] = useState([]);
  const [callNumbersChecked, setCallNumbersChecked] = useState({});
  const [areAllChecked, setAreAllChecked] = useState({});
  const [isMerged, setIsMerged] = useState(null)

  const store = {
    cdrs: [cdrs, setCdrs],
    filteredCdrs: [filteredCdrs, setFilteredCdrs],
    files: [files, setFiles],
    cdrsCompanies: [cdrsCompanies, setCdrsCompanies],
    callNumbersChecked: [callNumbersChecked, setCallNumbersChecked],
    areAllChecked: [areAllChecked, setAreAllChecked],
    isMerged: [isMerged, setIsMerged],
  }

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}
export default StoreProvider;
